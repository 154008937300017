import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whats-app-button',
  templateUrl: './whats-app-button.component.html',
  styleUrls: ['./whats-app-button.component.scss']
})
export class WhatsAppButtonComponent implements OnInit {

  constructor() { }

  sendWhats(){
    window.open("https://api.whatsapp.com/send?phone=525520899215");
  }

  ngOnInit(): void {
  }

}
