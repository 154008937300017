<!-- Footer-->
<footer class="footer py-4">
  <footer class="social-footer">
    <a href="https://play.google.com/store/apps/details?id=com.tj.agaveworldwideapp">
      <img class="img-style" src="../../../assets/android.png" />
    </a>
    <a href="https://apps.apple.com/mx/app/agave-worldwide/id1632500228">
      <img class="img-style" src="../../../assets/appios.png" />
    </a>
  </footer>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 text-lg-left">
        Copyright &copy; TJ Business Group
        <br>
        <a href="mailto:juanma@agaveworldwide.us">juanma@agaveworldwide.us</a>
        <br>
        <a href="mailto:mario.mg@agaveworldwide.us">mariomg.tj@gmail.com</a>
        
        <!-- This script automatically adds the current year to your website footer-->
        <!-- (credit: https://updateyourfooter.com/)-->
        <script>
          document.write(new Date().getFullYear());
        </script>
      </div>
      <div class="col-lg-4 my-3 my-lg-0">
        
        
        <a  href="https://www.instagram.com/agaveworldwide/">
        
          <svg class="instagram-logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="80" height="80"
            viewBox="0 0 551.034 551.034" style="enable-background:new 0 0 551.034 551.034;" xml:space="preserve">
            <path class="logo" id="XMLID_17_"
              d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z" />
            <path id="XMLID_81_" fill="#555"
              d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z" />
            <circle id="XMLID_83_" fill="#555" cx="418.306" cy="134.072" r="34.149" />
          </svg>

        </a>
        
        
      </div>
      <div class="col-lg-4 text-lg-right">
        <a class="mr-3" href="#!">Privacy Policy</a>
        <a href="#!">Terms of Use</a>
      </div>
    </div>
  </div>
  
</footer>
