<!-- About-->
<section class="page-section" id="about">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Steps</h2>
      <h3 class="section-subheading text-muted">
        Follow the steps to be part of us.
      </h3>
    </div>
    <ul class="timeline">
      <li>
        <div class="timeline-image">
          <img
            class="rounded-circle img-fluid"
            src="../../../../assets/template/assets/img/about/1.jpg"
            alt="..."
          />
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>Step 1</h4>
            <h4 class="subheading">Login with Us.</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">
              Create an account with us and access your panel, where you can manage your agave assets online!
            </p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image">
          <img
            class="rounded-circle img-fluid"
            src="../../../../assets/template/assets/img/about/2.jpg"
            alt="..."
          />
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>Step 2</h4>
            <h4 class="subheading">Check the agaves available.</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">
              In your panel you can see the agaves available in our fields, include location, 
              quantity, age and current value. Right there we put a calculator that tells you how much 
              you will receive in monthly and annual utility for your investment.
            </p>
          </div>
        </div>
      </li>
      <li>
        <div class="timeline-image">
          <img
            class="rounded-circle img-fluid"
            src="../../../../assets/template/assets/img/about/3.jpg"
            alt="..."
          />
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>Step 3</h4>
            <h4 class="subheading">Buy your first Agaves</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">
              Add to your cart all the agaves you want starting with $ 600 USD or 100 plants, 
              there are several forms of online payment or bank deposits. 
              Sell your Agaves when your money grows on the same platform so that other users can buy them.!
            </p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image">
          <img
            class="rounded-circle img-fluid"
            src="../../../../assets/template/assets/img/about/4.jpg"
            alt="..."
          />
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>Step 4</h4>
            <h4 class="subheading">Be Safe</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">
              Monitor your agaves in real time and see how your investment grows day by day with tangible assets. 
              Buy and sell as it suits you best. All our fields have insurances that protect the investment of our clients.!
            </p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image">
          <h4>
            Be Part
            <br />
            Of Our
            <br />
            Story!
          </h4>
        </div>
      </li>
    </ul>
  </div>
</section>

 


