import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import * as firebase from 'firebase/app';
import { StripeCustomersService } from '../../services/stripe-customers.service';

@Component({
  selector: 'checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent implements OnInit {
  sources: AngularFireList<any>;
  stripeCustomerInitialized: Boolean;
  newCreditCard: any;
  user: any;
  anonymous: Observable<firebase.default.User>;
  order: any;
  loading: boolean = false;

  constructor(
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public router: Router,
    public snackBar: MatSnackBar,
    private title: Title,
    private meta: Meta,
    private _stripeCustomersService: StripeCustomersService
  ) {
    this.user = globalService.user.getValue();
    this.order = globalService.order.getValue();

    if (!this.order || !this.order.billing || !this.order.billing.zip) {
      this.router.navigateByUrl('cart');
    }

    if (this.user) {
      this.sources = db.list('/stripe_customers/' + this.user.uid + '/sources');
    } else {
      this.afAuth
        .signInAnonymously()
        .catch(function (error) {
          console.log('auth error', error.message);
        })
        .then(() => {
          this.anonymous = afAuth.authState;
          this.anonymous.subscribe((anonymousUser) => {
            if (anonymousUser && anonymousUser.isAnonymous) {
              this.user = anonymousUser;
              this.sources = db.list(
                '/stripe_customers/' + this.user.uid + '/sources'
              );
            }
          });
        });
    }

    this.newCreditCard = {
      number: '4242424242424242',
      cvc: '111',
      exp_month: 1,
      exp_year: 2022,
      address_zip: '92200',
    };
  }

  ngOnInit() {
    this.title.setTitle('Payment');
    this.meta.updateTag(
      { content: 'Enter credit card information for payment of the order' },
      "name='description'"
    );
    (<any>window).Stripe.setPublishableKey(environment.stripe);
  }

  submitNewCreditCard() {
    if (
      this.newCreditCard.number &&
      this.newCreditCard.cvc &&
      this.newCreditCard.exp_month &&
      this.newCreditCard.exp_year
    ) {
      (<any>window).Stripe.card.createToken(
        {
          number: this.newCreditCard.number,
          cvc: this.newCreditCard.cvc,
          exp_month: this.newCreditCard.exp_month,
          exp_year: this.newCreditCard.exp_year,
          address_zip: this.order.billing.zip,
        },
        async (status, response) => {
          if (response.error) {
            this.newCreditCard.error = response.error.message;
          } else {
            try {
              this.loading = true;
              console.log(response, 'RESPONSE');
              const customerPaymentsResponse =
                await this._stripeCustomersService.getCustomerPaymentsMethods(
                  localStorage.getItem('stripeId')
                );
              console.log(customerPaymentsResponse, 'CPR');
              const findPayment = customerPaymentsResponse[
                'CustomerPayments'
              ].data.find(
                (p) =>
                  p.card.exp_month == response.card.exp_month &&
                  p.card.exp_year == response.card.exp_year &&
                  p.card.last4 == response.card.last4
              );
              console.log(findPayment, 'FIND PAYMENT');
              if (!findPayment) {
                const createCustomerPaymentMethod =
                  await this._stripeCustomersService.customerCreatePaymentMethod(
                    {
                      number: this.newCreditCard.number,
                      cvc: this.newCreditCard.cvc,
                      exp_month: this.newCreditCard.exp_month,
                      exp_year: this.newCreditCard.exp_year,
                    }
                  );
                console.log(createCustomerPaymentMethod, 'PAYMENT METHOD');

                const dataPayment = {
                  paymentMethodId:
                    createCustomerPaymentMethod['PaymentMethod'].id,
                  customerId: localStorage.getItem('stripeId'),
                };

                const attachPaymentMethod =
                  await this._stripeCustomersService.attachPaymentMethodToCustomer(
                    dataPayment
                  );
                console.log(
                  attachPaymentMethod['PaymentMethodAttached'],
                  'ATTACH PAYMENT METHOD'
                );
                const defaultSource =
                  await this._stripeCustomersService.setDefaultSource(
                    dataPayment
                  );
                console.log(defaultSource['response'], 'defaultSource');
              }
              this.loading = false;
              //TODO: Remplazar esto de abajo para pruebas por token: response.idBB    //FOR LIVE MODE STRIPE
              // token: `tok_${response.card.brand.toLowerCase()}`      //FOR TEST MODE STRIPE
              this.sources
                .push({
                  token: response.id,
                })
                .then(() => {
                  this.newCreditCard = {
                    number: '',
                    cvc: '',
                    exp_month: 1,
                    exp_year: 2022,
                    address_zip: '',
                  };
                  this.router.navigateByUrl('checkout/review');
                });
            } catch (error) {
              console.log(error, 'ERROR');
              this.snackBar.open(error, 'Error!', {
                duration: 3000,
                panelClass: ['warn-snackbar'],
              });
            }
          }
        }
      );
    } else {
      this.loading = false;
      let snackBarRef = this.snackBar.open(
        'You must complete the form',
        'OK!',
        {
          duration: 3000,
          panelClass: ['warn-snackbar'],
        }
      );
    }
  }
}
