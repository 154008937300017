import { Component, OnInit, DoCheck } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { take } from 'rxjs/operators';


export interface Calculator {
  precioKilo: number;
  precioPlanta: number;
}

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css',
  '../../../assets/template/css/styles.css'],
})
export class CalculatorComponent implements OnInit, DoCheck {
  private calculatorCollection: AngularFirestoreCollection<any>;
  calculatorData: Observable<any>;
  noPlantas: number = 100;
  precioPlanta: number = 0;
  totalInvertido: number;
  precioKilo: number = 0;
  aniosPlantada: number = 6.0;
  kilosPorPlanta: number = 50;
  utilidadBruta: number;
  utilidadNeta: number;
  utilidadMes: number;
  utilidadAnio: number;
  loading: boolean = false;

  constructor(
    private afs: AngularFirestore,
  ) {
    this.calculatorCollection = afs.collection<any>('calculator');
    this.calculatorData = this.calculatorCollection.valueChanges();
    
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const calculatorData = await this.calculatorData.pipe(take(1)).toPromise();
    this.precioKilo = calculatorData[0].precioKilo;
    this.precioPlanta = calculatorData[0].precioPlanta;
    this.totalInvertido = this.noPlantas * this.precioPlanta;
    this.utilidadBruta = this.noPlantas * this.kilosPorPlanta * this.precioKilo;
    this.utilidadNeta = this.utilidadBruta - this.utilidadBruta * 0.35;
    this.utilidadMes = this.utilidadNeta / (this.aniosPlantada * 12);
    this.utilidadAnio = this.utilidadNeta / this.aniosPlantada;
    this.loading = false;
  }

  ngDoCheck() {
    this.totalInvertido = this.noPlantas * this.precioPlanta;
    this.utilidadBruta = this.noPlantas * this.kilosPorPlanta * this.precioKilo;
    this.utilidadNeta = this.utilidadBruta - this.utilidadBruta * 0.35;
    this.utilidadMes = this.utilidadNeta / (this.aniosPlantada * 12);
    this.utilidadAnio = this.utilidadNeta / this.aniosPlantada;
  }

  add(value, nameValue) {
    console.log(value, nameValue);
    if (nameValue === 'noPlantas') {
      this.noPlantas += 1;
      console.log(this.noPlantas);
    } else if (nameValue === 'precioPlanta') {
      this.precioPlanta += 1;
    } else if (nameValue === 'totalInvertido') {
      this.totalInvertido += 1;
    } else if (nameValue === 'precioKilo') {
      this.precioKilo += 1;
    } else if (nameValue === 'aniosPlantada') {
      this.aniosPlantada += 1;
    } else {
      this.kilosPorPlanta += 1;
    }
  }

  subtract(value, nameValue) {
    console.log(value, nameValue);
    if (nameValue === 'noPlantas') {
      this.noPlantas -= 1;
      console.log(this.noPlantas);
    } else if (nameValue === 'precioPlanta') {
      this.precioPlanta -= 1;
    } else if (nameValue === 'totalInvertido') {
      this.totalInvertido -= 1;
    } else if (nameValue === 'precioKilo') {
      this.precioKilo -= 1;
    } else if (nameValue === 'aniosPlantada') {
      this.aniosPlantada -= 1;
    } else {
      this.kilosPorPlanta -= 1;
    }
  }

  inputChange(event) {
    const target = event.target;
    console.log(target.name);
    if (target.name === 'noPlantas') {
      this.noPlantas = target.value;
      console.log(this.noPlantas);
    } else if (target.name === 'precioPlanta') {
      this.precioPlanta = target.value;
    } else if (target.name === 'totalInvertido') {
      this.totalInvertido = target.value;
    } else if (target.name === 'precioKilo') {
      this.precioKilo = target.value;
    } else if (target.name === 'aniosPlantada') {
      this.aniosPlantada = target.value;
    } else {
      this.kilosPorPlanta = target.value;
    }
  }
}
