
<div class="container">
    <h3>My Portfolio</h3>
    <h3 *ngIf="router.url.includes('/admin/customer') && userObject && !userObject.email">
      Guest Customer
    </h3>
    <span *ngIf="userPortfolios">
      <mat-card class="order" routerLink="/account/portfolio/{{portfolio}}" *ngFor="let portfolio of userPortfolios; let i = index">
        <div class="order-info">
          <h3>
            <a routerLink="/account/portfolio/{{portfolio}}">#{{portfolio}}</a>
          </h3>
          <div class="date">{{ portfolioDates[i] | date }}</div>
        </div>
        <div class="actions">
          <button mat-raised-button routerLink="/account/portfolio/{{ portfolio }}">View</button>
        </div>
      </mat-card>
    </span>
    <p *ngIf="!userPortfolios || !userPortfolios.length" class="block-center">No portfolio</p>
  </div>