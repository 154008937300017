import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Title, Meta } from '@angular/platform-browser';
import { GlobalService } from '../../services/global.service';
import { LocalCartService } from '../../services/localcart.service';
import { StripeOrderService } from '../../services/stripe-order.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'checkout-review',
  templateUrl: './checkout-review.component.html',
  styleUrls: ['./checkout-review.component.scss'],
})
export class CheckoutReviewComponent implements OnInit {
  order: any;
  user: any;
  now: number;
  sources: AngularFireList<any[]>;
  charges: any;
  newCharge: any;
  loading: boolean = false;

  constructor(
    public db: AngularFireDatabase,
    public router: Router,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    private title: Title,
    private meta: Meta,
    private _stripeOrderService: StripeOrderService,
    public snackBar: MatSnackBar
  ) {
    this.charges = {};
    this.newCharge = {};
    this.order = globalService.order.getValue();
    this.user = globalService.user.getValue();
    const now = new Date().getTime();

    if (this.user) {
      this.sources = db.list('/stripe_customers/' + this.user.uid + '/sources');
    } else {
      router.navigateByUrl('cart');
    }

    if (this.sources) {
      this.sources.valueChanges().subscribe((s) => {
        this.newCharge.source = s[s.length - 1];
        console.log(s[s.length - 1]);
      });
    } else {
      router.navigateByUrl('cart');
    }

    if (this.order) {
      if (this.user) {
        this.order.uid = this.user.uid;
      }
      this.order.date = now;
      this.order.rdate = now * -1;
      this.order.status = 'PAID';
    }

    if (!this.order || !this.order.billing) {
      router.navigateByUrl('cart');
    }
  }

  ngOnInit() {
    this.title.setTitle('Review');
    this.meta.updateTag(
      { content: 'Review items and information for the order' },
      "name='description'"
    );
    console.log(this.user, 'USER');
  }

  async confirm() {
    // this.submitNewCharge();
    try {
      this.loading = true;
      let newKey = Math.abs(
        this.globalService.hashCode(this.order.date) +
          this.globalService.hashCode(this.order.shipping.email)
      );
      console.log('HOLAAA voy a guardar');
      const orderData = {
        email: this.order.shipping.email,
        customerId: localStorage.getItem('stripeId'),
        items: this.order.items.map((i) => {
          return { type: 'sku', parent: i.stripeSkuId };
        }),
      };
      console.log(orderData, '0RDER DATA');
      console.log(this.newCharge, 'CHARGE');
      const orderResponse = await this._stripeOrderService.createOrder(
        orderData
      );
      this.order.stripeId = orderResponse['Order'].id;
      const payResponse = await this._stripeOrderService.payOrder({
        orderId: this.order.stripeId,
        source: this.newCharge.source.token,
        email: this.user.email,
      });
      this.order.stripePayId = payResponse['Order'].id;

      this.db
        .list('/orders')
        .push(this.order)
        .then((item) => {
          this.globalService.cart.next(null);
          this.globalService.order.next(null);
          this.localCart.clearAll();
          if (this.user) {
            this.db.object('/users/' + this.user.uid + '/cart').remove();
            this.db
              .object('/users/' + this.user.uid + '/orders/' + item.key)
              .set(Date.now().toString());
          }
          this.loading = false;
          this.router.navigateByUrl('checkout/confirmation');
        });
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.snackBar.open(error.statusText && error.statusText, 'Error!', {
        duration: 3000,
        panelClass: ['warn-snackbar'],
      });
    }
  }

  submitNewCharge() {
    this.db.list('/stripe_customers/' + this.user.uid + '/charges').push({
      source: this.newCharge.source.id,
      amount: Math.floor(this.order.total * 100),
    });
  }
}
