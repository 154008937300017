import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-about',
  templateUrl: './template-about.component.html',
  styleUrls: ['./template-about.component.css',
  '../../../assets/template/css/styles.css']
})
export class TemplateAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
