<!-- Team-->
<section class="page-section bg-light" id="team">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Our Amazing Team</h2>
      <h3 class="section-subheading text-muted">
        Our network.
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="team-member">
          <img
            class="mx-auto rounded-circle"
            src="../../../../assets/template/assets/img/team/1.jpg"
            alt="..."
          />
          <h4>Field People </h4>
          <p class="text-muted">Our experts in planting, maintenance and harvesting.</p>
          
        </div>
      </div>
      <div class="col-lg-4">
        <div class="team-member">
          <img
            class="mx-auto rounded-circle"
            src="../../../../assets/template/assets/img/team/2.jpg"
            alt="..."
          />
          <h4>Clients</h4>
          <p class="text-muted">People who make it possible with investment injection.</p>
          
        </div>
      </div>
      <div class="col-lg-4">
        <div class="team-member">
          <img
            class="mx-auto rounded-circle"
            src="../../../../assets/template/assets/img/team/3.jpg"
            alt="..."
          />
          <h4>Admin People</h4>
          <p class="text-muted">People who supervise and monitor the good progress of the business.</p>
          
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <p class="large text-muted">
          .
        </p>
      </div>
    </div>
  </div>
</section>
