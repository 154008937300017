<div class="container">
  <h3>My Orders</h3>
  <h3 *ngIf="router.url.includes('/admin/customer') && userObject && !userObject.email">
    Guest Customer
  </h3>
  <span *ngIf="userOrders">
    <mat-card class="order" routerLink="/account/order/{{order}}" *ngFor="let order of userOrders; let i = index">
      <div class="order-info">
        <h3>
          <a routerLink="/account/order/{{order}}">#{{order}}</a>
        </h3>
        <div class="date">{{ orderDates[i] | date }}</div>
      </div>
      <div class="actions">
        <button mat-raised-button routerLink="/account/order/{{ order }}">View</button>
      </div>
    </mat-card>
  </span>
  <p *ngIf="!userOrders || !userOrders.length" class="block-center">No orders</p>
</div>