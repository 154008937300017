import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { GlobalService } from './services/global.service';
import { LocalCartService } from './services/localcart.service';
import { StripeCustomersService } from './services/stripe-customers.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  nav: Observable<any>;
  theme: Observable<any>;
  user: Observable<firebase.default.User>;
  menuList: any[];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    private _stipeCustomersService: StripeCustomersService
  ) {
    this.nav = db.list('/menus/nav').valueChanges();
    this.theme = db.object('/theme').valueChanges();

    this.nav.subscribe((items) => {
      this.menuList = [];
      for (let x = 0; x < items.length; x++) {
        this.menuList.push(items[x]);
        console.log(this.menuList);
      }
    });

    this.user = afAuth.authState;
    this.user.subscribe((currentUser) => {
      globalService.user.next(currentUser);

      if (currentUser) {
        this.db.object('/users/' + currentUser.uid).update({
          uid: currentUser.uid,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
          status: 'active',
        });

        this.db
          .object('/users/' + currentUser.uid)
          .valueChanges()
          .subscribe((user: any) => {
            if (user && user.cart) {
              globalService.cart.next(user.cart);
            }
            if (user && user.stripeId) {
              // console.log('NO GUARDAR STRIPE ID');
              localStorage.setItem('stripeId', user.stripeId);
              // console.log(user, 'USER');
            } else {
              // console.log('GUARDAR STRIPE ID');
              if (user) {
                const userName =
                  currentUser.displayName !== null &&
                  currentUser.displayName.length >= 20
                    ? currentUser.displayName.slice(0, 20)
                    : currentUser.displayName;
                this._stipeCustomersService
                  .createCustomer({ name: userName, email: currentUser.email })
                  .pipe(take(1))
                  .subscribe((data) => {
                    this.db.object('/users/' + currentUser.uid).update({
                      stripeId: data['Customer'].id,
                    });
                  });
                localStorage.removeItem('stripeId');
                // console.log(userName, 'USER NAME');
              }
            }
          });
      }

      if (!currentUser && this.localCart.cartHasItems()) {
        this.globalService.cart.next(this.localCart.cartGetItems());
      }
    });
  }

  // login() {
  //   this.afAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
  // }
  login() {
    window.location.href = 'https://agaveworldwide.web.app/';
  }

  goPorfolio(){
    this.router.navigateByUrl('portfolio');
  }

  logout() {
    this.globalService.cart.next(null);
    this.globalService.order.next(null);
    this.localCart.clearAll();
    this.afAuth.signOut();
  }
}
