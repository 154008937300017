<div class="container" *ngIf="product" itemscope itemtype="http://schema.org/Product">
  <div class="product-left">
    <img [src]="product.thumbnail" *ngIf="product.thumbnail" class="featured-image" alt="{{product.title}}" itemprop="image">
    <img src="../../../assets/placeholder.jpg" *ngIf="!product.thumbnail" class="featured-image" alt="{{product.title}}" itemprop="image">
  </div>
  <div class="product-right">
    <h2 itemprop="name">{{product.title}}</h2>
    <meta itemprop="priceCurrency" content="USD" />
    <p itemprop="price">{{product.price | currency:'USD':true}}</p>
    <p [innerHTML]="product.description | safeHtml" itemprop="description"></p>
    <div class="quantity-label">Qty</div>
    <mat-form-field class="quantity">
      <input matInput type="number" min="1" [(ngModel)]="product.quantity">
    </mat-form-field>
    <button mat-raised-button (click)="addToCart(product)">
      <span *ngIf="!globalCart || !globalCart[product.entityKey]">Add to</span>
      <span *ngIf="globalCart && globalCart[product.entityKey]">Update</span>
       Cart
    </button>
  </div>
</div>

<div [innerHtml]="jsonLDString" class="structured-data"></div>