<div class="container">
  <mat-card
    *ngFor="let post of posts | async  | search: searchTerm | sort:['-date']"
    routerLink="/blog/{{post.url}}"
    class="post"
    itemscope
    itemtype="http://schema.org/Article">
    <img [src]="getPostImage(post)" alt="{{post.title}}" itemprop="image">
    <div class="post-content">
      <h3><a routerLink="/blog/{{post.url}}" itemprop="headline">{{post.title}}</a></h3>
      <div class="date" itemprop="datePublished">{{post.date | date }}</div>
      <p>
        <span [innerHTML]="post.body | truncate: 200 " itemprop="articleBody"></span>
        <button mat-button routerLink="/blog/{{post.url}}">Read More</button>
      </p>
    </div>
  </mat-card>
  <p class="block-center" *ngIf="searchTerm && !(posts | async | search: searchTerm)?.length">No posts</p>
</div>