<!-- Masthead -->
<header class="masthead">
  <video #videoBackground class="video-background" muted loop playsinline>
    <source src="../../../assets/template/assets/img/agavevideo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  
  <div class="container">
    <div class="masthead-subheading">Welcome To Our Agave Fields!</div>
    <div class="masthead-heading text-uppercase">It's Nice To Meet You</div>

    <ng-template #showLogin>
      <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger" (click)="logIn()">admin Your Agave</a>
    </ng-template>
  </div>

  <footer class="social-footer">
    <a href="https://play.google.com/store/apps/details?id=com.tj.agaveworldwideapp">
      <img class="img-style" width="200px" src="../../../assets/android.png" />
    </a>
    <a href="https://apps.apple.com/mx/app/agave-worldwide/id1632500228">
      <img class="img-style" width="200px" src="../../../assets/appios.png" />
    </a>
  </footer>
</header>
