import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StripeCustomersService {
 //path: string = 'http://localhost:5000/api';
 path: string = 'https://agaveworlwide.herokuapp.com/api';

  constructor(private http: HttpClient) {}

  createCustomer(data: any) {
    return this.http.post(`${this.path}/create-customer`, data);
  }

  getCustomer(customerId: any) {
    return this.http.get(`${this.path}/get-customer?${customerId}`);
  }

  getAllCustomers() {
    return this.http.get(`${this.path}/get-all-customers`);
  }

  deleteCustomer(customerId: any) {
    return this.http.delete(`${this.path}/delete-customer?${customerId}`);
  }

  getCustomerPaymentsMethods(customerId: any): Promise<any> {
    return this.http
      .get(`${this.path}/get-customer-paymentsmethods?customerId=${customerId}`)
      .pipe(take(1))
      .toPromise();
  }

  customerCreatePaymentMethod(data: any): Promise<any> {
    return this.http
      .post(`${this.path}/create-paymentmethod`, data)
      .pipe(take(1))
      .toPromise();
  }

  attachPaymentMethodToCustomer(data: any): Promise<any> {
    return this.http
      .post(`${this.path}/paymentmethod/attach`, data)
      .pipe(take(1))
      .toPromise();
  }

  setDefaultSource(data: any): Promise<any> {
    return this.http
      .post(`${this.path}/customer/default-source`, data)
      .pipe(take(1))
      .toPromise();
  }
}
