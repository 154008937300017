import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-masthead',
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.css',
              '../../../assets/template/css/styles.css'],
})
export class MastheadComponent implements OnInit, AfterViewInit {
  @Output() showHome = new EventEmitter<boolean>();
  @ViewChild('videoBackground') video: ElementRef<HTMLVideoElement>;

  constructor( private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const videoElement = this.video.nativeElement;
    videoElement.muted = true;
    videoElement.autoplay = true;
    videoElement.loop = true;
    videoElement.play().catch(err => {
      console.error('Error attempting to play video:', err);
    });

  }
}
