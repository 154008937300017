<div class="container">
  <div class="grid">
    <div class="grid-tile" *ngFor="let product of products | async | search: searchTerm | sort:['weight']">
      <mat-card routerLink="/product/{{product.url}}" class="product" itemscope itemtype="http://schema.org/Product">
        <img [src]="getProductImage(product)" alt="{{product.title}}" itemprop="image">
        <div class="product-content">
          <h3>
            <a routerLink="/product/{{product.url}}" itemprop="name">
              {{product.title}}
            </a>
          </h3>
          <meta itemprop="priceCurrency" content="USD" />
          <div class="price" itemprop="price">{{product.price | currency:'USD':true }}</div>
        </div>
      </mat-card>
    </div>
    <p class="block-center" *ngIf="searchTerm && !(products | async | search: searchTerm)?.length">No products</p>
  </div>
</div>