<mat-card class="container">
  <h3>Review Order</h3>

  <h4>Shipping Info</h4>
  {{ order.shipping.name }}<br>
  {{ order.shipping.email }}<br>
  <span *ngIf="order.shipping.company">{{ order.shipping.company }}<br></span>
  {{ order.shipping.address }}<br>
  {{ order.shipping.city }}, {{ order.shipping.state }}, {{ order.shipping.zip }}<br>

  <h4>Billing Info</h4>
  {{ order.billing.name }}<br>
  {{ order.billing.email }}<br>
  <span *ngIf="order.billing.company">{{ order.billing.company }}<br></span>
  {{ order.billing.address }}<br>
  {{ order.billing.city }}, {{ order.billing.state }}, {{ order.billing.zip }}<br>

  <h4>Payment Info</h4>
  <div *ngIf="newCharge.source">
    <!-- <span *ngIf="newCharge.source && newCharge.source.token">
      {{ newCharge.source.monbrand }} &hellip;{{ newCharge.source.last4 }}
      (exp. {{ newCharge.source.exp_th }}/{{ newCharge.source.exp_year }})
    </span> -->
    <mat-progress-spinner mode="indeterminate" *ngIf="!newCharge.source.token"></mat-progress-spinner>
    <span class="verify-card" *ngIf="!newCharge.source || !newCharge.source.token">Verifying payment method</span>
  </div>

  <cart></cart>

  <a routerLink="/cart" class="edit">Edit Cart</a>

  <h4>Subtotal: {{ order.total | currency:'USD':true }}</h4>

  <div class="divBar" *ngIf="loading">
    <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
  </div>

  <button mat-raised-button [disabled]="!newCharge.source || !newCharge.source.token" class="confirm"
    [disabled]="loading" (click)="confirm()">Confirm Order</button>

  <div class="error" *ngIf="newCharge.error">{{ newCharge.error }}</div>
</mat-card>