

import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';


export interface Point {
  lat: number;
  lng: number;
}

export interface Points {
  points: Point[];
}


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss',
  '../../../assets/template/css/styles.css']
})
export class ServicesComponent implements OnInit {

  myLabel:string;
  private isButtonVisible = false;

  private polygonsCollection: AngularFirestoreCollection<any>;
  items: Observable<Points[]>;
  black = '#000000';
  paths: any[] = [];
  markers: any[] = [];
  icono = {
    url: '../../../assets/icons/agave-blue.png',
    scaledSize: {
      width: 25,
      height: 25,
    },
  };
  initMapa = {
    latitude: 24.351747,
    longitude: -102.404097,
    zoom: 5,
    zoomControl: false,
    scaleControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  };
  constructor(
    private afs: AngularFirestore,
    
  ) {
    this.polygonsCollection = afs.collection<Points[]>('polygons');
    this.items = this.polygonsCollection.valueChanges();
    
  }

  async ngOnInit(): Promise<void> {
    this.myLabel = 'Go to this Field';
    const items = await this.items.pipe(take(1)).toPromise();
    items.forEach((path) => {
      this.paths.push(path.points);
      this.markers.push(path.points[0]);
    });
    console.log(this.paths);
    console.log(this.markers, 'MARKERS');
  }

  watchPolygon(marker) {
    this.initMapa.latitude = marker.lat;
    this.initMapa.longitude = marker.lng;
    this.initMapa.zoom = 14;
    this.myLabel = '';
   this.isButtonVisible = true;
    console.log(marker);
    console.log(this.initMapa);
  }

  async goBack(){
    this.myLabel = 'Go to this Field';
    this.initMapa.zoom = 5;

    this.isButtonVisible = false;
   
  }

  zoomChange(event) {
    console.log(event);
  }


  styles = [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#6195a0',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          lightness: '0',
        },
        {
          saturation: '0',
        },
        {
          color: '#f5f5f2',
        },
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          lightness: '-3',
        },
        {
          gamma: '1.00',
        },
      ],
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#bae5ce',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fac9a9',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#4e4e4e',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#787878',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels.icon',
      stylers: [
        {
          hue: '#0a00ff',
        },
        {
          saturation: '-77',
        },
        {
          gamma: '0.57',
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#43321e',
        },
      ],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels.icon',
      stylers: [
        {
          hue: '#ff6c00',
        },
        {
          lightness: '4',
        },
        {
          gamma: '0.75',
        },
        {
          saturation: '-68',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#eaf6f8',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#c7eced',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          lightness: '-49',
        },
        {
          saturation: '-53',
        },
        {
          gamma: '0.79',
        },
      ],
    },
  ];

}
