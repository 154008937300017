<h1 style="height:85px;color: transparent;">.</h1> <!-- marioooooo  sin esta linea no funciona, es tema de que la mat-toolbar esta hasta arriba seguro en el scss -->


<mat-toolbar *ngIf="!router.url.includes('/admin')" class="main-header">

    <mat-icon class="material-icons menu-trigger" (click)="mobilenav.toggle()">menu</mat-icon>
    <img src="../assets/icons/logo-agave-chico.png" alt="logo" class="header-img"/>
    <h1 class="titulo-store"><a routerLink="/" (click)="mobilenav.close()">{{ (theme | async)?.siteName }}</a></h1>
    <span class="fill-space"></span>
    <ul>
        <span *ngFor="let item of nav | async" class="nav-item">
            <li *ngIf="item.label && item.label !== '' && item.url && item.url !== ''">
                <a *ngIf="!item.url.includes('.')" routerLink="{{item.url}}" [ngClass]="{ 'color-main': router.url.includes(item.url) }">{{item.label}}</a>
                <a *ngIf="item.url.includes('.')"  href="{{item.url}}" target="_blank">{{item.label}}</a>
            </li>
        </span>
        <li class="search-nav">
            <mat-icon (click)="mobilenav.close()" routerLink="/search" [ngClass]="{ 'color-main': router.url.includes('search') }">search</mat-icon>
        </li>
        <li class="cart-nav" (click)="mobilenav.close()" [ngClass]="{ 'color-main': router.url.includes('cart') }">
            <cart-icon></cart-icon>
        </li>
       <!--  <li>
            <button mat-menu-item routerLink="/account/orders" *ngIf="(user | async)?.displayName">My Account</button>
        </li> -->
       <!--  <li>
            <button mat-menu-item (click)="goPorfolio()" *ngIf="(user | async)?.displayName">My Portfolio</button>
        </li> -->
        <li *ngIf="(user | async)?.displayName" class="user-nav">
            <img src="{{ (user | async)?.photoURL }}" class="user-photo" (click)="mobilenav.close()" [mdMenuTriggerFor]="userMenu">
            <button mat-icon-button [matMenuTriggerFor]="menu" >
                <img src="../assets/icons/menu-sub.png" alt="logo" class="header-img-sub"/>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/account/orders">
                    <mat-icon>person</mat-icon>
                    <span>My Account</span>
                </button>
                <button mat-menu-item (click)="goPorfolio()">
                    <mat-icon>book</mat-icon>
                    <span>My Portfolio</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>Logout</span>
                    </button>
              </mat-menu>
        </li>
     <!--    <li *ngIf="(user | async)?.displayName" class="user-nav">
            <img src="{{ (user | async)?.photoURL }}" class="user-photo" (click)="mobilenav.close()" [mdMenuTriggerFor]="userMenu">
            <mat-menu #userMenu="matMenu">
                <button mat-menu-item routerLink="/account/orders">My Account</button>
                <button mat-menu-item (click)="logout()">Logout</button>
            </mat-menu>
            
        </li> -->
        <li>
            <button mat-raised-button (click)="login()" *ngIf="!(user | async)?.displayName">Login</button>
        </li>
        
    </ul>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #mobilenav  mode="over" class="mobile-nav">
        <div *ngFor="let item of nav | async" class="mobile-nav-item">
            <a routerLink="{{item.url}}" (click)="mobilenav.close()" *ngIf="item.label && item.label !== '' && item.url && item.url !== ''">
                {{item.label}}
            </a>
        </div>
    </mat-sidenav>
    <router-outlet></router-outlet>
</mat-sidenav-container>
