<div class="container" [ngClass]="{ 'category': router.url.includes('category'), 'categories': !router.url.includes('category') }">
  <h3><a routerLink="/category/{{ categoryObject.slug }}">{{ categoryObject.name }}</a></h3>
  <section class="grid" *ngIf="categoryProducts">
    <span class="grid-tile" *ngFor="let product of categoryProducts | sort:['weight']">
      <div *ngIf="product.published" itemscope itemtype="http://schema.org/Product">
        <mat-card routerLink="/product/{{ product.url }}" class="product">
          <img [src]="getProductImage(product)" itemprop="image" alt="{{product.title}}">
          <div class="product-content">
            <h3>
              <a routerLink="/product/{{ product.url }}" itemprop="name">
                {{ product.title }}
              </a>
            </h3>
            <meta itemprop="priceCurrency" content="USD" />
            <div class="price" itemprop="price">
              {{ product.price | currency:'USD':true }}
            </div>
          </div>
        </mat-card>
      </div>
    </span>
    <div class="grid-tile button-container">
      <span class="see-more">
        <button mat-raised-button routerLink="/category/{{ categoryObject.slug }}">See more {{ categoryObject.name }}</button>
      </span>
    </div>
  </section>
</div>
<div *ngIf="(products | async) && router.url.includes('category')" class="bottom-cta">
  <button mat-button routerLink="/">View All Categories</button>
</div>