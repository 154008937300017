<div class="login-frame">
    <div *ngIf="!(admin | async)?.uid">
        <button mat-raised-button class="bw block google-login" (click)="loginWithGoogle()">
            <img src="../../assets/google-icon.png">
            <span *ngIf="showSignUp === false">Login with Google</span>
            <span *ngIf="showSignUp === true">Sign Up with Google</span>
        </button>
        <div class="block-center">or</div>
    </div>
    <div *ngIf="!(admin | async)?.uid">
        <mat-form-field class="block">
            <input matInput type="email" placeholder="email" [(ngModel)]="newEmail">
        </mat-form-field>
        <mat-form-field class="block">
            <input matInput type="password" placeholder="password" minlength="6" [(ngModel)]="newPassword">
        </mat-form-field>
        <button mat-raised-button class="bw" (click)="loginWithEmail()" *ngIf="showSignUp === false">
            Login with Email
        </button>
    </div>
    <div *ngIf="!(admin | async)?.uid">
        <div *ngIf="showSignUp === false" class="sign-up-trigger">
            Don't have an account? <a routerLink="/register">Sign up</a>
        </div>
        <div *ngIf="showSignUp === true">
            <button mat-raised-button class="bw" (click)="signUpWithEmail()">
                Sign Up with Email
            </button>
        </div>
    </div>
    <div *ngIf="showSignUp === true && !(admin | async)?.uid" class="sign-up-trigger">
        Already have an account? <a routerLink="/login">Log in</a>
    </div>
    <div class="hint block-center" *ngIf="!(admin | async)?.uid">
        You must be an approved admin to access the admin panel
    </div>
    <button mat-button *ngIf="(admin | async)?.uid" (click)="logout()" class="logout">Logout</button>
</div>
