<!-- Portfolio Grid-->
<section class="page-section bg-light" id="portfolio">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Portfolio</h2>
      <h3 class="section-subheading text-muted">
        Our Agave Fields.
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-6 mb-4">
        <div class="portfolio-item">
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal1">
            <div class="portfolio-hover">
              <div class="portfolio-hover-content">
                <i class="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img
              class="img-fluid"
              src="../../../../assets/template/assets/img/portfolio/01-thumbnail.jpg"
              alt="..."
            />
          </a>
          <div class="portfolio-caption">
            <div class="portfolio-caption-heading">Arandas, Jalisco.</div>
            <div class="portfolio-caption-subheading text-muted">
              10 Hectareas
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mb-4">
        <div class="portfolio-item">
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal2">
            <div class="portfolio-hover">
              <div class="portfolio-hover-content">
                <i class="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img
              class="img-fluid"
              src="../../../../assets/template/assets/img/portfolio/02-thumbnail.jpg"
              alt="..."
            />
          </a>
          <div class="portfolio-caption">
            <div class="portfolio-caption-heading">Irapuato, Guanajuato.</div>
            <div class="portfolio-caption-subheading text-muted">
              5 Hectareas
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mb-4">
        <div class="portfolio-item">
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal3">
            <div class="portfolio-hover">
              <div class="portfolio-hover-content">
                <i class="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img
              class="img-fluid"
              src="../../../../assets/template/assets/img/portfolio/03-thumbnail.jpg"
              alt="..."
            />
          </a>
          <div class="portfolio-caption">
            <div class="portfolio-caption-heading">San Miguel, Jalisco.</div>
            <div class="portfolio-caption-subheading text-muted">8 Hectareas</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
        <div class="portfolio-item">
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal4">
            <div class="portfolio-hover">
              <div class="portfolio-hover-content">
                <i class="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img
              class="img-fluid"
              src="../../../../assets/template/assets/img/portfolio/04-thumbnail.jpg"
              alt="..."
            />
          </a>
          <div class="portfolio-caption">
            <div class="portfolio-caption-heading">Tepatitlán, Jalisco.</div>
            <div class="portfolio-caption-subheading text-muted">4 Hectareas</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mb-4 mb-sm-0">
        <div class="portfolio-item">
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal5">
            <div class="portfolio-hover">
              <div class="portfolio-hover-content">
                <i class="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img
              class="img-fluid"
              src="../../../../assets/template/assets/img/portfolio/05-thumbnail.jpg"
              alt="..."
            />
          </a>
          <div class="portfolio-caption">
            <div class="portfolio-caption-heading">Tequila, jalisco.</div>
            <div class="portfolio-caption-subheading text-muted">
              7 Hectareas
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="portfolio-item">
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal6">
            <div class="portfolio-hover">
              <div class="portfolio-hover-content">
                <i class="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img
              class="img-fluid"
              src="../../../../assets/template/assets/img/portfolio/06-thumbnail.jpg"
              alt="..."
            />
          </a>
          <div class="portfolio-caption">
            <div class="portfolio-caption-heading">La Barca, Jalisco.</div>
            <div class="portfolio-caption-subheading text-muted">
              5 Hectareas
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
