import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-inversion',
  templateUrl: './inversion.component.html',
  styleUrls: ['./inversion.component.scss']
})
export class InversionComponent implements OnInit {


isDataAvailable:boolean = false;
public portfolioData: any;
portfolioContent: any;

public trace1 = {
  x: ["2021(1)", "2021(2)", "2021(3)", "2021(4)"],
  y: [750000,770000,790000,810000],
  type: 'scatter',
  mode: 'lines+markers',
  name: 'inversion1'
};



public data = [this.trace1];
public layout = {width: 450, height: 440, title: 'Investment'}
 

  constructor(
    public route: ActivatedRoute,
    public db: AngularFireDatabase,
  ) {
     //** firebase */
   // this.currentCategory = this.db.object('/approvals/categories/' + params.key);
  //  this.db.object('/portfolios/' + 'xxx' + '/y').valueChanges().subscribe((portfoliosObject:any) => {
             
  //   const values = Object.keys(portfoliosObject).map(key => portfoliosObject[key]);
  //   console.log(values[2])
  //   this.trace1.y.push(values[2])
    
  //   //this.data = values; 
  //   console.log(this.trace1)
  // });
//** firebase */
   }

  ngOnInit(): void {

    if (window.screen.width < 500) { // 768px portrait
      this.layout = {width: 450, height: 440, title: 'Investment Mobile'}

    }else{
      this.layout = {width: 1250, height: 740, title: 'Investment '}
    }
   
    this.route.params.subscribe((params: Params) => {
     
    });





   this.loadData();

       
  }


    loadData(){

      


      this.route.params.subscribe((params: Params) => {

      
        //for root portfolio data
        this.portfolioContent = this.db.object('/portfolios/' + params.key);
        this.portfolioContent.valueChanges().subscribe((o) => {
          if (o) {
            this.portfolioData = o;
            this.portfolioData.key = params.key;
          } else {
            this.portfolioData = {
              title: 'Order Not Found',
              body: ''
            }
          }
        });
        //for child portfolio data    
        this.db.object('/portfolios/' + params.key + '/y').valueChanges().subscribe((portfoliosObject: any) => {

          const valuesY = Object.keys(portfoliosObject).map(key => portfoliosObject[key]);
          console.log(valuesY)
          this.trace1.y = valuesY;

        });

        //for child portfolio data   
        this.db.object('/portfolios/' + params.key + '/x').valueChanges().subscribe((portfoliosObjectX: any) => {

          const valuesX = Object.keys(portfoliosObjectX).map(key => portfoliosObjectX[key]);
          console.log(valuesX)
          this.trace1.x = valuesX;
          this.isDataAvailable = true; // ngIf html;
        });


      });

  }







}
