<!-- Services-->
<section class="page-section" id="services">
  <div class="container">

    <div class="text-center">
      <h2 class="section-heading text-uppercase">Locations</h2>
      <h3 class="section-subheading text-muted">
        Our agave Fields available for you.
      </h3>
    </div>



    <div class="col-md-9 seccion-mapa">
      <div class="secciones-informacion">
        <div class="card">
          <div class="card-body px-3 py-4-5">
            <div class="row">
              <div class="col-md-4">
                <div class="stats-icon purple">
                  <mat-icon style="color: #0090aa">grass</mat-icon>
                </div>
              </div>
              <div class="col-md-8">
                <h6 class="text-muted font-semibold">
                  {{ "Total Plants" }}
                </h6>
                <h6 class="font-extrabold mb-0">{{ "489000" }}</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body px-3 py-4-5">
            <div class="row">
              <div class="col-md-4">
                <div class="stats-icon purple">
                  <mat-icon style="color: #0090aa">grass</mat-icon>
                </div>
              </div>
              <div class="col-md-8">
                <h6 class="text-muted font-semibold">
                  {{ "Available Plants" }}
                </h6>
                <h6 class="font-extrabold mb-0">{{ "289000" }}</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body px-3 py-4-5">
            <div class="row">
              <div class="col-md-4">
                <div class="stats-icon purple">
                  <mat-icon style="color: #0090aa">grass</mat-icon>
                </div>
              </div>
              <div class="col-md-8">
                <h6 class="text-muted font-semibold">
                  {{ "Acquired Plants" }}
                </h6>
                <h6 class="font-extrabold mb-0">{{ "200000" }}</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body px-3 py-4-5">
            <div class="row">
              <div class="col-md-4">
                <div class="stats-icon purple">
                  <mat-icon style="color: #0090aa">grass</mat-icon>
                </div>
              </div>
              <div class="col-md-8">
                <h6 class="text-muted font-semibold">
                  {{ "Total Invested"  }}
                </h6>
                <h6 class="font-extrabold mb-0">{{ "15000000" }}</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body px-3 py-4-5">
            <div class="row">
              <div class="col-md-4">
                <div class="stats-icon purple">
                  <mat-icon style="color: #0090aa">grass</mat-icon>
                </div>
              </div>
              <div class="col-md-8">
                <h6 class="text-muted font-semibold">
                  {{ "Total Investors" }}
                </h6>
                <h6 class="font-extrabold mb-0">{{ "340" }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="mapa">
        <ng-container>
          <agm-map [style.height.px]="500" [styles]="styles" [zoom]="initMapa.zoom" [latitude]="initMapa.latitude"
            [longitude]="initMapa.longitude" [scrollwheel]="false" (zoomChange)="zoomChange($event)" #agmMap>
            <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng"
              [iconUrl]="icono">
              <agm-info-window>
                <ng-container>
                  <div class="container">
                    <button class="btn btn-info" (click)="watchPolygon(marker)">
                      {{myLabel}}
                    </button>
                    <button class="btn btn-info" *ngIf="this.isButtonVisible" (click)="goBack()">
                      Go Back
                    </button>
                  </div>
                </ng-container>
              </agm-info-window>
            </agm-marker>
            <agm-polygon [strokeColor]="black" [visible]="true" *ngFor="let path of paths" [paths]="path">
            </agm-polygon>
          </agm-map>
        </ng-container>
      </div>
    </div>
  </div>





  <div class="text-center">
    <h2 class="section-heading text-uppercase">How does it work?</h2>
    <h3 class="section-subheading text-muted">
    You do not have to worry about your plant, just invest and see how your money increases, 
    AgaveWorldWide will take care of all the operations that your plant requires. At the end of the maturation time of the agave, 
    only between 5 and 6 years, AgaveWorldWide will complete its sale and a form of commission for all operating expenses will only keep 35% of the total sale.
    </h3>
    <h2 class="section-heading text-uppercase">Services</h2>
    <h3 class="section-subheading text-muted">
      Learn about the services available to you.
    </h3>
  </div>
  <div class="row text-center">
    <div class="col-md-4">
      <span class="fa-stack fa-4x">
        <i class="fas fa-circle fa-stack-2x" style="color:#0090aa"></i>
        <i class="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
      </span>
      <h4 class="my-3">E-Commerce</h4>
      <p class="text-muted-2">
        Buy agave assets completely online,
        invest and see how your money increases in your Admin Panel.
        You can also sell part of your assets or all of your acquired assets.
      </p>
    </div>
    <div class="col-md-4">
      <span class="fa-stack fa-4x">
        <i class="fas fa-circle fa-stack-2x" style="color:#0090aa"></i>
        <i class="fas fa-laptop fa-stack-1x fa-inverse"></i>
      </span>
      <h4 class="my-3">Platform</h4>
      <p class="text-muted-2">
        Agave asset management platform where you can buy,
        sell and monitor your agave investments.
        It is completely online, in real time and with high security.
      </p>
    </div>
    <div class="col-md-4">
      <span class="fa-stack fa-4x">
        <i class="fas fa-circle fa-stack-2x" style="color:#0090aa"></i>
        <i class="fas fa-lock fa-stack-1x fa-inverse"></i>
      </span>
      <h4 class="my-3">Security</h4>
      <p class="text-muted-2">
        Security for your agave assets with insurance that covers and protects your investment at all times.
        This allows you to never worry about your money, just monitor your earnings.
      </p>
    </div>
  </div>

</section>