import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StripeOrderService {
  //path: string = 'http://localhost:5000/api';
  path: string = 'https://agaveworlwide.herokuapp.com/api';

  constructor(private http: HttpClient) {}

  createOrder(data: any): Promise<any> {
    return this.http
      .post(`${this.path}/order/create`, data)
      .pipe(take(1))
      .toPromise();
  }

  payOrder(data: any): Promise<any> {
    return this.http
      .post(`${this.path}/order/pay`, data)
      .pipe(take(1))
      .toPromise();
  }
}
